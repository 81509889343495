<template>
    <div class="repairDetail">
        <rx-nav-bar title="查看详情"></rx-nav-bar>
        <div class="applyDetailTitle">申请详情</div>
        <div class="applyDetail">
            <div class="applyDetailAddress">你空间上看到好快的哈，好快的宽带哈，宽带好快'</div>
            <div class="rule"></div>
        </div>
        <div class="check">

        </div>
    </div>
</template>
<script>

    import RxNavBar from "../../../components/rongxun/rx-navBar/navBar";

    export default {
        name: 'repairDetail',
        components: {
            RxNavBar,
        },
        data() {
            return {
                evaluation:'5',
                num:'5',
                items: {
                    acceptor:'沉思',
                    result:'通过',
                    bears:'公司',
                    finalCost:'45.00',
                    time:'2020-12-12 12:30',
                    note:'你空间上看到好快的哈，好快的宽带哈，宽带好快'
                },

            }
        },

        methods: {

            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },

        },

    }
</script>
<style lang="less" scoped>
    //维修详情
  .repairDetail{
      .applyDetailTitle{
          font-size: 12px;
          color: red;
          width: 92%;
          margin: 10px auto;
      }
      .applyDetail{
          width: 92%;
          margin: 10px auto;
          background-color: #fafafa;
          border-radius: 8px;
          .applyDetailAddress{
              font-size: 15px;
              width: 92%;
              margin: 0 auto;
              height:30px;
              padding: 10px 0;
          }
          .rule{
              width: 100%;
              border: 0.5px solid #9d9d9d;
          }
      }
  }
</style>
